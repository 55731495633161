import React, { useCallback, Fragment, useMemo, useEffect } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import { useFormik } from "formik";


const PagePaForm = () => {
	const navigate = useNavigate();

	const initialValues = useMemo(() => {
		return {
			privacy: false,
			tnc: false,
			acknowledge: false,
		};
	  }, []);

	const formik = useFormik({
		initialValues, // Add initial values if needed
		validationSchema: yup.object({
			tnc: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			privacy: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			certWord: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			acknowledge: yup.boolean().oneOf([true], ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
		  //console.log("Form submitted with values:", values);
		  onHandleSubmit(values);
		},
	  });


	const onHandleNavigate = useCallback(() => {
		navigate(pathnames.paForm);
	}, [navigate]);

	const onHandleSubmit = (values) => {
		sessionStorage.setItem('fromDescript', 'true');
		onHandleNavigate();
	};

	//prettier-ignore
	const onHandleTnc = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("tnc", !value);
	}, [formik]);

	//prettier-ignore
	const onHandlePrivacy = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("privacy", !value);
	}, [formik]);

	//prettier-ignore
	const onHandleAcknowledge = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("acknowledge", !value);
	}, [formik]);

	//prettier-ignore
	const PrivacyLabel = useCallback(() => {
		return (
			<Fragment>
				I consent to the<a className="enquiry__tnc-pdf" href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noreferrer"> Data Protection and Privacy Policy</a> and allow my data to be processed according to applicable law.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	//prettier-ignore
	const TncLabel = useCallback(() => {
		return (
			<Fragment>
				I have read, understood, and agree to abide by this <a className="enquiry__tnc-bold">Terms and Conditions</a>.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	//prettier-ignore
	const AcknowledgeLabel = useCallback(() => {
		return (
			<Fragment>
				By submitting, I acknowledge the information provided is true and based on the best of my knowledge.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	useEffect(() => {
		// Check if the session flag is set
		const fromPa = sessionStorage.getItem('fromHome');
	
		if (!fromPa) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromHome');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [navigate]);
	
	  const tableHeaderStyle = {
		border: '1px solid #ddd',
		padding: '8px',
		backgroundColor: '#f2f2f2',
		textAlign: 'left',
	};
	
	const tableCellStyle = {
		border: '1px solid #ddd',
		padding: '8px',
	};

	// Example data for the table
	const data = [
		{ benefit: 'Accidental Death', sumIns: '10,000' },
		{ benefit: 'Accidental Total Permanent Disablement', sumIns: '10,000' },

	  ];
	

	return (
		<div className="page-pa-descript">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							<div className="enquiry__wrapper">
								<h1 className="enquiry__big-title">Terms & Conditions</h1>
								<h1 className="enquiry__title">Campaign Overview</h1>
								<p className="enquiry__description-title">This easycover.my Complimentary Personal Accident Lucky Draw Campaign (“Campaign”) is organised by VKA
								Wealth Planners Sdn Bhd (“VKA”) and will run from 1 October 2024 to 31 December 2024 (“Campaign Period”).</p>

								<h1 className="enquiry__title">Eligibility</h1>
								<p className="enquiry__description-title">This Campaign is open to:</p>
								<p className="enquiry__description-sub">Malaysian citizens or permanent residents of Malaysia aged between 18 to 65 years old.</p>
								<p className="enquiry__description-sub">Participants must download and register an account on easycover.my app using the dedicated QR Code provided during the Campaign Period.</p>
								<p className="enquiry__description-sub">A total of 10,000 policies will be given away throughout the Campaign Period. VKA will announce the successful applicants quarterly in January 2025 and April 2025, respectively, while stocks last. The Campaign will end earlier if all policies are claimed.</p>
								<p className="enquiry__description-sub">VKA reserves the right to reject any application without providing a reason.</p>

								<h1 className="enquiry__title">How to Participate</h1>
								<p className="enquiry__description-title">To participate in this Campaign, participants must:</p>
								<p className="enquiry__description-title">1.	Register an account during the Campaign Period using the dedicated QR Code.</p>
								<p className="enquiry__description-sub">Participants will be required to provide additional personal details, including nominee information for verification purposes.</p>
								<p className="enquiry__description-title">2.	Download the App:</p>
								<p className="enquiry__description-sub">Download the easycover.my app from the Apple App Store, Google Play Store, or Huawei App Gallery.</p>
								<p className="enquiry__description-title">3.	Like and Follow Us on Facebook:</p>
								<p className="enquiry__description-sub">Like and Follow @easycover.my on Facebook for updates and announcements on the Quarterly Complimentary Personal Accident Activation.</p>
								<p className="enquiry__description-title">Successful selected participants will receive one (1) Complimentary Personal Accident coverage of RM10,000, valid for one year from the activation date.</p>

								<h1 className="enquiry__title">Personal Accident Coverage</h1>
								<p className="enquiry__description-title">The complimentary Personal Accident coverage is underwritten by Etiqa General Takaful Berhad (EGTB), subject to its applicable terms and conditions. The policy will include the following benefits:</p>
								
								<div className="enquiry__table">
									<table>
        							    <thead>
        							        <tr>
        							            <th className="enquiry__td-header">Benefit</th>
        							            <th className="enquiry__td-header">Sum Insured (RM)</th>
        							        </tr>
        							    </thead>
        							    <tbody>
        							        {data.map((row, index) => (
        							            <tr key={index}>
        							                <td className="enquiry__td-row">{row.benefit}</td>
        							                <td className="enquiry__td-row">{row.sumIns}</td>
        							            </tr>
        							        ))}
        							    </tbody>
        							</table>
								</div>
								
								<p className="enquiry__description-title">Subjectivities:</p>
								<p className="enquiry__description-sub">No known/reported losses as at to-date and for the past 3-years.</p>
								<p className="enquiry__description-sub">No deterioration of loss until inception date of certificate.</p>
								<p className="enquiry__description-sub">Age limit of 16 to 65 years old only.</p>
								<p className="enquiry__description-sub">Coverage limited to accidental cause only.</p>
								<p className="enquiry__description-sub">No backdate cover.</p>
								<p className="enquiry__description-sub">No change in Sum Covered in the risk between the date submissions made to us and the proposed effective date of this quote.</p>
								<p className="enquiry__description-sub">On named basis and subject to AML screening.</p>
								<p className="enquiry__description-sub">As per EGTB’s standard terms & conditions, clauses, warranties and exclusions.</p>
								<p className="enquiry__description-sub">Infectious or Contagious Disease Exclusion During a PHEIC.</p>
								<p className="enquiry__description-sub">Communicable Disease Exclusion.</p>
								<p className="enquiry__description-sub">Cyber Risk Exclusion.</p>
								<p className="enquiry__description-sub">Sanction Limitation and Exclusion.</p>
								<p className="enquiry__description-sub">Incorporation of Takaful Aqad.</p>
								<p className="enquiry__description-sub">Subject to Shariah approval for SNC risk i.e nature of business and/or trade code (if applicable).</p>
								<p className="enquiry__description-sub">EGTB reserves the right to amend and/or withdraw the terms and conditions if additional info or documentation submitted after this changes our valuation of this risk.</p>
								<p className="enquiry__description-sub">Claim shall be paid directly to the individual covered person or his/her legal representative.</p>
								<p className="enquiry__description-sub">Excluded occupation:</p>
								<p className="enquiry__description-sub-sub">• Police force, Rescue Squad and Firemen whilst on active duty.</p>
								<p className="enquiry__description-sub-sub">• Armed Security guard/forces/organisations.</p>
								<p className="enquiry__description-sub-sub">• Airline personnel and air crew whilst in the course of occupation.</p>
								<p className="enquiry__description-sub-sub">• Professional sports team and/or sports as a profession.</p>
								<p className="enquiry__description-sub-sub">• Professional racing risks of any kind other than racing on foot, swimming and racing in dinghies.</p>
								<p className="enquiry__description-sub-sub">• Naval, military or Air Force service or operations whilst on active military duties.</p>
								<p className="enquiry__description-title">Please note that the policy document will only be available in your easycover.my app account for successfully selected participants. For full details and accurate information, refer to the policy document after you have been selected.</p>

								<h1 className="enquiry__title">Quarterly Complimentary Personal Accident Activation</h1>
								<p className="enquiry__description-sub">1. Submissions for the Complimentary Personal Accident application to EGTB will be processed quarterly in batches during January 2025 and April 2025, respectively, while stocks last.</p>
								<p className="enquiry__description-sub">2. Like and Follow @easycover.my on Facebook for updates and announcements on the Quarterly Complimentary Personal Accident Activation.</p>
								<p className="enquiry__description-sub">3. Participants will be notified via email upon selection for the quarterly activation, and successful applicants can view their Complimentary Personal Accident policy document through their account on the easycover.my app.</p>
								<p className="enquiry__description-sub">4. Each eligible participant is entitled to receive the Complimentary Personal Accident Coverage only once.</p>

								<h1 className="enquiry__title">Coverage Period & Renewal</h1>
								<p className="enquiry__description-sub">1. The Complimentary Personal Accident coverage will begin from the date of activation and will remain valid for one year.</p>
								<p className="enquiry__description-sub">2. Upon expiry, participants may choose to renew the Personal Accident coverage at their own expense through easycover.my. (Optional)</p>
								
								<h1 className="enquiry__title">Campaign Termination & Modifications</h1>
								<p className="enquiry__description-title">VKA reserves the right to:</p>
								<p className="enquiry__description-sub">• Change or amend these Terms & Conditions at any time.</p>
								<p className="enquiry__description-sub">• Terminate, suspend, or extend the Campaign Period without prior notice.</p>
								<p className="enquiry__description-sub">• Any changes or announcements will be communicated via easycover.my website and social media platforms. It is the participant’s responsibility to review these Terms periodically.</p>
								
								<h1 className="enquiry__title">Liability & Indemnity</h1>
								<p className="enquiry__description-title">To the extent permitted by law:</p>
								<p className="enquiry__description-sub">• VKA and its related corporations will not be liable for any loss or damage (including loss of opportunity) resulting from participation in this Campaign.</p>
								<p className="enquiry__description-sub">• Participants agree to release and indemnify VKA and its related corporations from any liability (including negligence) for personal injury, loss, damage (including indirect or consequential losses), or breach of these Terms arising from participation in this Campaign.</p>
								
								<h1 className="enquiry__title">Force Majeure</h1>
								<p className="enquiry__description-sub">VKA shall not be liable for failure or delay in performing any obligations under this Campaign due to events beyond its reasonable control, including but not limited to acts of God, war, strikes, industrial action, fire, floods, or any other unforeseen events.</p>
								
								<h1 className="enquiry__title">Governing Law</h1>
								<p className="enquiry__description-sub">This Campaign is governed by and construed in accordance with the laws of Malaysia. Participants agree to submit to the exclusive jurisdiction of the courts of Malaysia. </p>
								
								<div className="enquiry__divider" />
								<div className="enquiry__checkbox">

									{/* prettier-ignore */}
									<AppCheckbox required name="tnc" onClick={onHandleTnc} label={<TncLabel />} error={formik.errors.tnc} value={formik.values.tnc} touched={formik.touched.tnc} disabled={formik.isSubmitting} />

									{/* prettier-ignore */}
									<AppCheckbox required name="privacy" onClick={onHandlePrivacy} label={<PrivacyLabel />} error={formik.errors.privacy} value={formik.values.privacy} touched={formik.touched.privacy} disabled={formik.isSubmitting} />

									{/* prettier-ignore */}
									<AppCheckbox required name="acknowledge" onClick={onHandleAcknowledge} label={<AcknowledgeLabel />} error={formik.errors.acknowledge} value={formik.values.acknowledge} touched={formik.touched.acknowledge} disabled={formik.isSubmitting} />

								</div>
								
								<p className="enquiry__description-important">The insurance plan is underwritten by Etiqa General Takaful Berhad. VKA Wealth Planners is an approved Financial Advisory Firm under Bank Negara Malaysia.</p>

								<div className="enquiry__button-container">
									<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PagePaForm;
